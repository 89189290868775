import { Box, HStack, Icon, StackProps } from "@chakra-ui/react"
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs"

export const starIcons = (stars: number) =>
  [...Array(5)].map((star, index) => {
    return (
      <Icon
        key={index}
        as={
          index < stars ? (stars < index + 1 ? BsStarHalf : BsStarFill) : BsStar
        }
        color={"yellow.400"}
        width={4}
        height={4}
      />
    )
  })

const Ratings = ({
  stars,
  count,
  ...props
}: StackProps & {
  stars: number
  count: number
}) => {
  return (
    <HStack
      spacing={1}
      {...props}
      alignItems={"center"}
    >
      <HStack spacing={0}>{starIcons(stars)}</HStack>
      <Box fontSize={"xs"}>{`(${
        Intl.NumberFormat("en-AE", {
          notation: "compact",
          maximumFractionDigits: 0,
          useGrouping: true,
          compactDisplay: "short",
        }).format(count) + `${count > 999 ? "+" : ""}`
      })`}</Box>
    </HStack>
  )
}

export default Ratings
