import { Box, Flex, Image, Link, Skeleton } from "@chakra-ui/react"
import Carousel from "@components/Carousel/Carousel"
import Slide from "@components/Carousel/Slide"
import { BannerProps } from "@type/banners"
import { ConditionalWrapper } from "@utils/common"
import NextLink from "next/link"
import { useMemo } from "react"

const Banners = ({
  promoType,
  banners,
  promosLoading,
}: {
  promoType?: string
  banners: BannerProps[]
  promosLoading: boolean
}) => {
  const bannerHeight = promoType === "home" ? 267 : 148

  const slides = useMemo(() => {
    return banners?.map((banner) => {
      return (
        <Slide key={banner.id}>
          <ConditionalWrapper
            condition={!!banner.link_url}
            wrapper={(children) => (
              <Link
                as={NextLink}
                href={`${banner.link_url}`}
              >
                {children}
              </Link>
            )}
          >
            <Image
              src={`${banner.image}`}
              alt={banner.title || "gzb promo"}
              htmlWidth={banner.width || 800}
              htmlHeight={banner.height || bannerHeight}
            />
          </ConditionalWrapper>
          {!!banner.terms_url && (
            <Flex
              justifyContent={"flex-end"}
              fontSize={"10px"}
              mb={2}
            >
              <Link
                href={banner.terms_url}
                isExternal
              >
                {"T&Cs apply"}
              </Link>
            </Flex>
          )}
        </Slide>
      )
    })
  }, [bannerHeight, banners])

  return (
    <Box w={"full"}>
      <Skeleton
        startColor={"teal.400"}
        endColor={"teal.600"}
        isLoaded={!promosLoading}
        width={"full"}
        aspectRatio={800 / bannerHeight}
      >
        <Carousel
          slides={slides}
          showDots={false}
          showArrows={false}
          loop={true}
          playOnInit={true}
        />
      </Skeleton>
      {promosLoading && (
        <Flex
          justifyContent={"flex-end"}
          mb={2}
        >
          <Skeleton
            h={4}
            w={16}
          />
        </Flex>
      )}
    </Box>
  )
}

export default Banners
