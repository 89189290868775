import { Box } from "@chakra-ui/react"
import { useEffect, useState } from "react"

type ShipsCountdownProps = {
  shipsTargetHour: number
}
const ShipsCountdown = ({ shipsTargetHour }: ShipsCountdownProps) => {
  const [remainingTime, setRemainingTime] = useState<string>("")

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Maintain en-US locale to get 12-hour time
      const currentTime = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Dubai",
      })

      const currentDateTime = new Date(currentTime)
      const targetDateTime = new Date(currentTime)
      targetDateTime.setHours(shipsTargetHour, 0, 0, 0)

      if (targetDateTime <= currentDateTime) {
        targetDateTime.setDate(targetDateTime.getDate() + 1)
      }

      const difference = targetDateTime.getTime() - currentDateTime.getTime()

      if (difference <= 0) {
        clearInterval(intervalId)
        setRemainingTime("24hrs")
      } else {
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        )
        const seconds = Math.floor((difference % (1000 * 60)) / 1000)

        setRemainingTime(`${hours}h ${minutes}m ${seconds}s`)
      }
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box fontSize={"xs"}>
      {"Ships in "}
      {remainingTime}
    </Box>
  )
}

export default ShipsCountdown
