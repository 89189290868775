import { Box, Flex, FlexProps } from "@chakra-ui/react"

interface ProductPriceProps extends FlexProps {
  listPrice?: number
  price: number
  currency: string
  isSpecial?: boolean
  discountValue?: number
}

const ProductPrice = ({
  listPrice,
  price,
  currency,
  isSpecial,
  discountValue,
  ...rest
}: ProductPriceProps) => {
  const amount = price.toFixed(2).split(".")

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={isSpecial ? "column" : "row"}
    >
      <Flex
        alignItems={"flex-start"}
        {...rest}
      >
        <Box
          mt={isSpecial ? 2 : 1}
          fontSize={"xs"}
          fontWeight={700}
        >
          {currency ? currency : "AED"}
        </Box>
        <Box
          fontSize={"2xl"}
          ms={1}
          fontWeight={700}
        >
          {Number(amount[0]).toLocaleString()}
        </Box>
        <Box
          mt={1}
          fontSize={"xs"}
          ms={"1px"}
        >
          {amount[1]}
        </Box>
      </Flex>
      {listPrice && discountValue && (
        <Flex
          flexDirection={"column"}
          color={"gray.600"}
          fontSize={"xs"}
          mt={isSpecial ? -2 : 0}
          ms={isSpecial ? 0 : 1}
          alignItems={isSpecial ? "center" : "flex-start"}
        >
          <Box textDecoration={"line-through"}>
            {`${listPrice.toLocaleString("en-AE", {
              style: "currency",
              currency: "AED",
            })}`}
          </Box>

          <Box
            color={"red.600"}
            fontWeight={700}
            mt={isSpecial ? 0 : -1}
          >
            {`${discountValue}% OFF`}
          </Box>
        </Flex>
      )}
    </Flex>
  )
}

export default ProductPrice
