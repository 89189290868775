import { IconButton, IconButtonProps } from "@chakra-ui/react"

const CarouselButton = (props: IconButtonProps) => {
  return (
    <IconButton
      position={"absolute"}
      top={"50%"}
      variant={"ghost"}
      size={"sm"}
      {...props}
    />
  )
}

export default CarouselButton
