import { thumbHashToDataURL } from "thumbhash"

export const blurDataURLFromThumbHash = (base64: string) => {
  const binary = new Uint8Array(
    atob(base64)
      .split("")
      .map((x) => x.charCodeAt(0))
  )
  return thumbHashToDataURL(binary)
}
