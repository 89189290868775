import { BannerProps } from "@type/banners"

export const home_promos: BannerProps[] = [
  {
    id: 1,
    image: `/b/home/buy-more-save-more.webp`,
    title: "Buy More, Save More",
    width: 1080,
    height: 360,
  },
  {
    id: 2,
    image: `/b/home/cash-on-delivery.webp`,
    title: "Cash on Delivery",
    width: 1080,
    height: 360,
  },
  {
    id: 3,
    image: `/b/home/free-delivery-and-returns.webp`,
    title: "Free Delivery and Returns",
    width: 1080,
    height: 360,
  },
]

export const product_promos: BannerProps[] = [
  {
    id: 1,
    image: `/b/product/heavy-cart-happy-heart.webp`,
    title: "Buy More, Save More",
    width: 1080,
    height: 200,
  },
  {
    id: 2,
    image: `/b/product/turbo-shipping.webp`,
    title: "Turbo Shipping",
    width: 1080,
    height: 200,
    terms_url: "/g/shipping",
  },
]
