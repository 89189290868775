import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  HStack,
  Link,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react"
import ProductPrice from "@components/Product/ProductPrice"
import ShipsCountdown from "@components/Product/ShipsCountdown"
import { ConditionalWrapper } from "@utils/common"
import { blurDataURLFromThumbHash } from "@utils/image"
import { Hit } from "instantsearch.js"
import Image from "next/image"
import NextLink from "next/link"
import { Fragment, useEffect, useRef } from "react"

export type SpecialProductsProps = {
  results: {
    hits: Hit[]
    more: string
  }
  title: string
  style: "grid" | "slider"
  inProductPage?: boolean
  shipsTargetHour?: number
}

const Product = ({ hit }: { hit: Hit }) => (
  <Link
    as={NextLink}
    href={`/p/${hit.alternateName.en_AE}/${hit.id}`}
    textDecoration={"none"}
    _hover={{
      textDecoration: "none",
    }}
  >
    <Card
      w={36}
      m={2}
      bg={useColorModeValue("white", "blackAlpha.300")}
      shadow={"none"}
    >
      <CardBody
        p={0}
        overflow={"hidden"}
      >
        <Flex
          alignItems={"center"}
          minH={56}
          maxH={56}
        >
          {hit?.image?.length > 0 ? (
            <Image
              alt={hit.name.en_AE}
              src={`${process.env.NEXT_PUBLIC_IMAGE_CDN}${hit.image[0].contentUrl}?class=mblock`}
              width={hit.image[0].width.value}
              height={hit.image[0].height.value}
              loader={({ src }) => src}
              unoptimized
              placeholder={"blur"}
              blurDataURL={blurDataURLFromThumbHash(
                hit.image[0].exifData.value
              )}
            />
          ) : (
            <Image
              alt={hit.name.en_AE}
              src={`${process.env.NEXT_PUBLIC_IMAGE_CDN}/b-img/${hit.gtin}.png?class=mblock`}
              width={800}
              height={1200}
              placeholder={"blur"}
              blurDataURL={blurDataURLFromThumbHash(
                "JCoGDQDPmHd4enfoWIyGeMNwaQ2H"
              )}
              unoptimized
            />
          )}
        </Flex>
      </CardBody>
      <CardFooter
        p={0}
        justifyContent={"center"}
      >
        {hit?.offers?.lowPrice && (
          <ProductPrice
            listPrice={Number(hit.offers.priceSpecification?.price)}
            price={Number(hit.offers.lowPrice)}
            discountValue={Number(hit.offers?.offers?.[0].identifier?.value)}
            currency={hit.offers.priceCurrency}
            isSpecial
          />
        )}
      </CardFooter>
    </Card>
  </Link>
)

const SpecialProducts = ({
  results,
  title,
  style,
  inProductPage,
  shipsTargetHour,
}: SpecialProductsProps) => {
  const [yellow200, gray50] = useToken("colors", ["yellow.200", "gray.50"])

  const specialProductsRef = useRef<HTMLDivElement>(null)

  // Reset special products scroll to left on product change
  useEffect(() => {
    if (specialProductsRef.current) {
      specialProductsRef.current.scrollLeft = 0
    }
  }, [results])

  return (
    <ConditionalWrapper
      condition={!inProductPage}
      wrapper={(children) => (
        <Box
          as={"section"}
          overflow={"hidden"}
        >
          {children}
        </Box>
      )}
    >
      <Fragment>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={"1px solid"}
          borderColor={"gray.200"}
          bgColor={"gray.100"}
          p={2.5}
          w={"full"}
        >
          <ConditionalWrapper
            condition={!!shipsTargetHour}
            wrapper={(children) => <Box>{children}</Box>}
          >
            <Fragment>
              <Heading
                color={"gray.900"}
                fontWeight={700}
                fontSize={"md"}
              >
                {title}
              </Heading>
              {!!shipsTargetHour && (
                <ShipsCountdown shipsTargetHour={shipsTargetHour} />
              )}
            </Fragment>
          </ConditionalWrapper>

          <Link
            as={NextLink}
            title={`${title} - See More Books`}
            href={`${results?.more}`}
            color={"gray.900"}
            fontSize={"xs"}
          >
            {"more books"}
          </Link>
        </Flex>

        {style === "grid" && (
          <Flex
            flexWrap={"wrap"}
            alignItems={"stretch"}
            justifyContent={"space-evenly"}
            w={"full"}
            p={2}
          >
            {results?.hits?.map((hit, index) => (
              <Product
                key={index}
                hit={hit}
              />
            ))}
          </Flex>
        )}

        {style === "slider" && (
          <HStack
            ref={specialProductsRef}
            w={"full"}
            flexWrap={"nowrap"}
            overflowX={"auto"}
            alignItems={"flex-start"}
            p={2}
            sx={{
              scrollbarColor: `${yellow200} transparent`,
            }}
            css={{
              "&::-webkit-scrollbar": {
                height: "6px",
              },
              "&::-webkit-scrollbar-track": {
                height: "4px",
                backgroundColor: gray50,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: yellow200,
                borderRadius: "6px",
              },
            }}
          >
            {results?.hits?.map((hit, index) => (
              <Product
                key={index}
                hit={hit}
              />
            ))}
          </HStack>
        )}
      </Fragment>
    </ConditionalWrapper>
  )
}

export default SpecialProducts
