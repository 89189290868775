import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
} from "@chakra-ui/react"
import CarouselButton from "@components/Carousel/Buttons"
import Autoplay from "embla-carousel-autoplay"
import useEmblaCarousel from "embla-carousel-react"
import { Fragment, ReactNode, useCallback, useEffect, useState } from "react"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { RxDot, RxDotFilled } from "react-icons/rx"

type CarouselProps = {
  slides: ReactNode[]
  showArrows: boolean
  showDots: boolean
  extraDot?: IconButtonProps | JSX.Element | null
  delay?: number
  loop?: boolean
  playOnInit?: boolean
}

const Carousel = ({
  slides,
  showArrows,
  showDots,
  extraDot,
  delay,
  loop,
  playOnInit,
}: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: "center",
      loop: loop,
    },
    [
      Autoplay({
        delay: delay || 5000,
        stopOnInteraction: false,
        stopOnMouseEnter: true,
        playOnInit: playOnInit,
        rootNode: (emblaRoot) => emblaRoot.parentElement,
      }),
    ]
  )
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const scrollPrev = useCallback(() => {
    emblaApi && emblaApi.scrollPrev()
  }, [emblaApi])
  const scrollNext = useCallback(() => {
    emblaApi && emblaApi.scrollNext()
  }, [emblaApi])
  const scrollTo = useCallback(
    (index: number) => {
      emblaApi && emblaApi.scrollTo(index)
    },
    [emblaApi]
  )

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setSelectedIndex(emblaApi.selectedScrollSnap())
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
  }, [emblaApi, setSelectedIndex])

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    setScrollSnaps(emblaApi.scrollSnapList())
    emblaApi.on("select", onSelect)
    emblaApi.on("reInit", onSelect)
  }, [emblaApi, setScrollSnaps, onSelect])

  return (
    <>
      <Flex
        justifyContent={"center"}
        w={"full"}
      >
        <Box position={"relative"}>
          <Box
            overflow={"hidden"}
            ref={emblaRef}
          >
            <Flex>
              {slides?.map((slide, index) => (
                <Fragment key={index}>{slide}</Fragment>
              ))}
            </Flex>
          </Box>
          {showArrows && slides.length > 1 && (
            <>
              <CarouselButton
                aria-label={"Previous"}
                onClick={scrollPrev}
                isDisabled={!prevBtnEnabled}
                icon={
                  <Icon
                    as={FaChevronLeft}
                    width={6}
                    height={6}
                  />
                }
                left={0}
                transform={"translateY(-50%) translateX(-50%)"}
              />
              <CarouselButton
                aria-label={"Next"}
                onClick={scrollNext}
                isDisabled={!nextBtnEnabled}
                icon={
                  <Icon
                    as={FaChevronRight}
                    width={6}
                    height={6}
                  />
                }
                right={0}
                transform={"translateY(-50%) translateX(50%)"}
              />
            </>
          )}
        </Box>
      </Flex>
      {showDots && (
        <HStack
          justifyContent={"center"}
          alignItems={"center"}
          w={"full"}
        >
          {scrollSnaps.map((_, index) => (
            <IconButton
              aria-label={`Go to slide ${index + 1}`}
              key={index}
              onClick={() => scrollTo(index)}
              icon={
                index === selectedIndex ? (
                  <Icon
                    as={RxDotFilled}
                    width={8}
                    height={8}
                  />
                ) : (
                  <Icon
                    as={RxDot}
                    width={8}
                    height={8}
                  />
                )
              }
              variant={"ghost"}
              size={"sm"}
            />
          ))}
          {extraDot && <>{extraDot}</>}
        </HStack>
      )}
    </>
  )
}

export default Carousel
