import { Box, BoxProps } from "@chakra-ui/react"

const Slide = (props: BoxProps) => {
  return (
    <Box
      width={"full"}
      flex={"0 0 100%"}
      position={"relative"}
      {...props}
    />
  )
}

export default Slide
